
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { ErrorMessage, Field, Form } from "vee-validate";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import { ref } from "yup";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { checkAuth } from "@/core/config/custom";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [],
      detail: {
        name: "",
        picBank: "",
        note: "",
      },
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      areaName: "",
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      isShow: true,
    };
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_BANK);

    this.getIdBank();

    this.getData(this.paging.size, this.paging.page);

    this.isShow = !checkAuth(
      JSON.parse(localStorage.getItem(`user_account`)!).role,
      [`MARKETING_LEADER`, `MARKETING_MEMBER`]
    );
    setCurrentPageBreadcrumbs("Detail Bank Pusat", ["Data"]);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getIdBank() {
      const toast = useToast();
      const $route = useRoute();

      ApiService.getWithoutSlug(
        "crmv2/main_bank/bank/office/" + this.$route.params.id
      )
        .then((res) => {
          this.detail.name = res.data.name;
          this.detail.picBank = res.data.picBank;
          this.detail.note = res.data.note;
        })
        .catch((e) => {
          toast.error(e.res.data.detail);
        });
    },

    getData(size, page) {
      this.isLoading = true;

      let search = "?";
      if (this.areaName) {
        search = "?areaName=" + this.areaName + "&";
      } else {
        search;
      }

      const $route = useRoute();

      ApiService.getWithoutSlug(
        "crmv2/main_bank/bank/office/area/all/" +
          this.$route.params.id +
          search +
          "page=" +
          page +
          "&size=" +
          size +
          "&sort=areaName&dir=1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    deleteRow(item) {
      this.isLoading = true;
      const toast = useToast();
      const $route = useRoute();

      ApiService.delete(
        "crmv2/main_bank/bank/office/area/" +
          this.$route.params.id +
          "/" +
          item.areaId
      )
        .then((res) => {
          toast.success("Hapus Data Berhasil");

          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })

        .catch((error) => {
          toast.error("Gagal Hapus");
          this.isLoading = false;
        });
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
  },
});
