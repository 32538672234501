export const checkAuth = (role, propRole, checker = '||') => {
    let checkArray = propRole.map((item) => role.includes(item));
    let checkResult = checkArray.reduce((prev, curr) => {
        switch (checker) {
            case '||':
                // console.log(prev, curr, prev || curr)
                return prev || curr;
            case '&&':
                return prev && curr;

            default:
                break;
        }
    }, checker != '||');
    // console.log(checkResult)
    return checkResult;
}

export const isContain = (arr, value) => {
    return arr.includes(value)
}

export default { checkAuth, isContain }